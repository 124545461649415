import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { ViewAlertPage } from 'src/app/view-alert/view-alert.page';

@Component({
  selector: 'app-alertas',
  templateUrl: './alertas.page.html',
  styleUrls: ['./alertas.page.scss'],
})
export class AlertasPage implements OnInit {

  id:any = null;
  usuario:any = null;
  alertas:any = [];
  time_interval:any; 

  constructor( public route:ActivatedRoute, public _api: ApiService, public loadingCtrl:LoadingController,
              private router: Router, public modalCtrl:ModalController, public alertController:AlertController ) { 
    this.id = this.route.snapshot.params.id;
    this.usuario = localStorage.getItem("user");
    console.log("Id param",this.id)
  }

  ngOnInit() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
          // Show loading indicator
          console.log("Inicia objetos");
      }

      if (event instanceof NavigationEnd) {
          console.log("Termina objetos");
          if( this.time_interval ){     
            clearInterval( this.time_interval );
          }
          // Hide loading indicator
      }

      if (event instanceof NavigationError) {
          // Hide loading indicator

          // Present error to user
          console.log(event.error);
      }
    });
  }

  ionViewWillEnter(){

    if( this.time_interval ){     
      clearInterval( this.time_interval );
    }
    this.getAlertas();
    let num = 0;
    // this.time_interval = setInterval(()=>{
    //   num++;
    //   this.getAlertas();
    //   if( num > 1 ){
    //     clearInterval(this.time_interval);
    //   }
    // }, 20000, this);
    
  }

  async getAlertas(){

    const loading = await this.loadingCtrl.create({
      message:"Cargando alertas...",
      duration:2000
    });

    loading.present();

    this.alertas = [];

    this._api.alertas({user:this.usuario}).subscribe((data:any)=>{
      this.alertas = data.data;
      console.log(this.alertas);
    });
  }

  async presentAlert( message:string ) {
    const alert = await this.alertController.create({      
      subHeader: 'Mensaje',
      message: message,
      buttons: ['OK'],
    });

    await alert.present();
  }

  async alertView( item:any ){
    console.log(item);

    if( item.status_gps == "MENSAJE" ){
      return this.presentAlert(item.mensaje);
    }

    item.tipo = 1; // alerta

    const modal = await this.modalCtrl.create({
      component:ViewAlertPage,
      // initialBreakpoint: 0.25,
      componentProps:{
        data: item
      }
    }); 

    modal.present();
  }


  handleRefresh(event) {
    this.getAlertas();
    setTimeout(() => {
      // Any calls to load data go here
      event.target.complete();
    }, 2000);
  };

}
